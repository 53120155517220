<template>
  <ConfirmDialog
    :value.sync="show"
    :title="title"
    :positiveButtonText="buttonTitle"
    :positiveAction="generatePatientCard"
    :loading="loading"
    :disabledPositiveButton="loading"
    :loadingPositiveButton="loading"
    persistent
    dontHideOnPositiveAction
    negativeButtonColor="primary"
    :compact="false"
    showCloseBtn
  >
    <div slot="content">
      <v-layout column v-if="!allDoctors && !forceAllDoctors">
        <DefaultSubtitle class="mx-4 mt-4">Wizyty:</DefaultSubtitle>
        <v-checkbox
          hide-details
          :ripple="false"
          class="zg-text-control zg-small-margin mx-4 mb-4"
          label="Wszystkie wizyty w danym leczeniu"
          :disabled="allDoctors"
          v-model="allAppointments"
        ></v-checkbox>
      </v-layout>
      <v-layout column>
        <AppointmentSelect
          v-if="!allAppointments && !allDoctors && !forceAllDoctors"
          label="Wizyta"
          placeholder="Wybierz wizytę..."
          :patient="patient"
          :session="session"
          :owner="owner"
          :disabled="allDoctors"
          :value.sync="appointment"
        ></AppointmentSelect>
        <DefaultSubtitle class="mx-4"
          >Które dodatkowe elementy dołączyć do wydruku?</DefaultSubtitle
        >
        <v-layout column shrink class="mx-4">
          <v-checkbox
            hide-details
            :ripple="false"
            class="zg-text-control zg-small-margin"
            label="Zdjęcia"
            v-model="printPhotos"
          ></v-checkbox>
          <v-checkbox
            hide-details
            :ripple="false"
            class="zg-text-control zg-small-margin"
            label="Wszystkie wersje zmian"
            v-model="printChanges"
          ></v-checkbox>
          <v-checkbox
            hide-details
            class="zg-text-control zg-small-margin"
            :ripple="false"
            label="Uwagi"
            v-model="printComments"
          ></v-checkbox>
          <v-checkbox
            hide-details
            class="zg-text-control zg-small-margin"
            :ripple="false"
            label="Opisy wizyt"
            v-model="printDescription"
          ></v-checkbox>
        </v-layout>
        <DefaultSubtitle class="mx-4 mt-4">Sposób wydruku:</DefaultSubtitle>
        <v-layout column shrink class="mx-4">
          <v-radio-group
            v-model="printCompressed"
            hide-details
            class="zg-small-margin"
          >
            <v-radio
              class="zg-text-control"
              label="Skompresuj treść"
              :value="true"
            ></v-radio>
            <v-radio
              class="zg-text-control"
              label="Podziel wizyty na osobne strony"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </v-layout>
        <v-layout column v-if="allAppointments && !allDoctors && !forceAllDoctors">
          <DefaultSubtitle class="mx-4 mt-4">Leczenie:</DefaultSubtitle>
          <v-checkbox
            hide-details
            class="zg-text-control mx-4 zg-small-margin"
            :ripple="false"
            label="Wszystkie karty pacjenta"
            :disabled="allDoctors"
            v-model="allSessions"
          ></v-checkbox>
        </v-layout>
        <v-layout column>
          <DefaultSubtitle class="mx-4 mt-4"
            >Karty innych terapeutów:</DefaultSubtitle
          >
          <v-checkbox
            hide-details
            class="zg-text-control mx-4 zg-small-margin"
            :ripple="false"
            label="Wszystkie karty innych terapeutów"
            v-model="allDoctors"
            :disabled="forceAllDoctors"
          ></v-checkbox>
        </v-layout>
      </v-layout>
    </div>
  </ConfirmDialog>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import Validators from "@/mixins/Validators";
import PatientService from "@/services/patient.service";

export default {
  mixins: [Validators, ProxyCRUDMixin],
  timers: {
    checkPatientCard: { time: 1000 },
  },
  props: {
    value: {
      default: false,
    },
    mode: {
      type: String,
      default: "DOWNLOAD",
    },
    session: {
      default: () => {},
    },
    patient: {
      default: () => {},
    },
    owner: {},
    forceAllDoctors: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      printPhotos: false,
      printChanges: false,
      printComments: false,
      printCompressed: true,
      printDescription: false,
      allSessions: false,
      appointment: null,
      allAppointments: true,
      allDoctors: this.forceAllDoctors || false,
      generationRequestId: null,
    };
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    LabelTextField: () => import("@/components/LabelTextField"),
    AppointmentSelect: () => import("@/components/selects/AppointmentSelect"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    title() {
      if (this.mode === "DOWNLOAD") {
        return "Opcje pobierania";
      }
      return "Opcje drukowania";
    },
    buttonTitle() {
      if (this.mode === "DOWNLOAD") {
        return "Pobierz";
      }
      return "Drukuj";
    },
  },
  methods: {
    checkPatientCard() {
      PatientService.checkGeneratedPatientCardStatus(this.generationRequestId)
        .then((reply) => {
          if (reply.status === true) {
            this.handleSuccess("Karta pacjenta została wygenerowana");
            this.$emit("action", reply.file);
            this.show = false;
          } else if (reply.status === false) {
            this.handleError("Coś poszło nie tak");
          } else {
            this.$timer.stop("checkPatientCard");
            this.$timer.start("checkPatientCard");
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak");
        });
    },
    generatePatientCard() {
      this.beforeRequest();
      PatientService.generatePatientCard(
        {
          session: this.session?.id,
          print_photos: this.printPhotos,
          print_changes: this.printChanges,
          print_comments: this.printComments,
          print_compressed: this.printCompressed,
          print_description: this.printDescription,
          print_all_sessions: !this.allAppointments ? false : this.allSessions,
          print_all_doctors_sessions: !this.allDoctors
            ? false
            : this.allDoctors,
          card: !this.allAppointments ? this.appointment.id : undefined,
        },
        this.patient?.id,
        this.owner?.id
      )
        .then((reply) => {
          this.generationRequestId = reply.id;
          this.$timer.start("checkPatientCard");
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak");
        });
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
.zg-small-margin {
  margin-top: 8px !important;
}
</style>